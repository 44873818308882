import React, { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import { adminAPI } from '../../../api';
import Pagination from '../../../components/Pagination';
import Table from '../../../components/Table';
import { formatDate } from '../../../helpers';

const columns = [
  {
    label: 'E-mail',
    field: 'email',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '30%',
  },
  {
    label: 'Sent On',
    field: 'invitation_sent_on',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '12%',
  },
  {
    label: 'Created',
    field: 'has_created',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: 'When created',
    field: 'created_at',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
  {
    label: 'User name',
    field: 'full_name',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '20%',
  },
  {
    label: 'Conversations',
    field: 'conversations',
    textAlign: 'center',
    fontWeight: 'semibold',
    width: '10%',
  },
];

const renderRowFn = (user) => {
  return {
    full_name: user?.full_name ? user?.full_name : '---',
    email: user?.email,
    conversations: user?.conversation_count,
    has_created: user?.has_created ? 'Yes' : 'No',
    created_at: user?.account_creation_date
      ? formatDate(user.account_creation_date)
      : '---',
    invitation_sent_on: user?.invite_sent_on
      ? formatDate(user.invite_sent_on)
      : '---',
  };
};

const Invites = ({ searchQuery, filter }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const { data, refetch } = useQuery(
    ['getReportInvites', { page: currentPage, search: searchQuery, filter }],
    adminAPI.getReportInvites
  );

  useEffect(() => {
    if (
      data?.pagination?.current_page &&
      currentPage !== data?.pagination?.current_page
    ) {
      setCurrentPage(data.pagination.current_page);
    }
  }, [data?.pagination?.current_page, currentPage]);

  useEffect(() => {
    refetch();
  }, [currentPage, searchQuery, filter, refetch]);

  return (
    <>
      {data?.reports && (
        <Table
          data={data?.reports}
          columns={columns}
          reconstructorFn={renderRowFn}
          hasCheckbox={false}
        />
      )}
      <div style={{ paddingTop: 20 }}>
        <Pagination
          currentPage={currentPage}
          totalPage={data?.pagination?.total_pages}
          onPageChange={(page) => setCurrentPage(page)}
        />
      </div>
    </>
  );
};

export default Invites;
